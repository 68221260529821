import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {ticksToSeconds} from "../../../util/date";
import {TableCollapsible, TableCollapsibleRow} from "../../../components/tables/TableCollapsible";
import {Dropdown} from "react-bootstrap";
import {getBranchName, getCarrierName} from "../../../util/data-hooks";
import {application} from "../../../util/application";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BookingStatusBadge from "../../../components/badges/BookingStatusBadge";
import {faClock} from "@fortawesome/pro-solid-svg-icons";

import "./styles.scss";

const bookingStatusesForPin = application.bookings_status_types.filter(status => status.showPin).map(status => status.statusCode);

const BookingsTable = ({bookingsData, carriersLoading, carriersData, carriersError, branchesLoading, branchesData, branchesError, compactTable, handleUpdateModal, handleCancelModal, bookingsColumns, currentSlotDate}) => {

    return (
        <TableCollapsible hover striped className={`bookings-table table-one-two ${compactTable ? "table-compact" : ""}`}>
            {
                !compactTable &&
                <thead>
                    <tr>
                        <th className={!!bookingsColumns && !bookingsColumns.bookingstatus ? "d-none" : ""}>Status</th>
                        <th className={!!bookingsColumns && !bookingsColumns.direction ? "d-none" : ""}>Direction</th>
                        <th className={!!bookingsColumns && !bookingsColumns.bookingnoPin ? "d-none" : ""}>Booking PIN</th>
                        <th className={!!bookingsColumns && !bookingsColumns.branchcode ? "d-none" : ""}>Depot</th>
                        <th className={!!bookingsColumns && !bookingsColumns.carriercode ? "d-none" : ""}>Carrier</th>
                        <th className={!!bookingsColumns && !bookingsColumns.containerno ? "d-none" : ""}>Container</th>
                        <th className={!!bookingsColumns && !bookingsColumns.bookingreference ? "d-none" : ""}>Booking Ref.</th>
                        <th className={!!bookingsColumns && !bookingsColumns.regono ? "d-none" : ""}>Truck Rego</th>
                        <th className={!!bookingsColumns && !bookingsColumns.isocode ? "d-none" : ""}>ISO</th>
                        <th className={!!bookingsColumns && !bookingsColumns.createdbyname ? "d-none" : ""}>Created By</th>
                        <th className={!!bookingsColumns && !bookingsColumns.commodity ? "d-none" : ""}>Commodity</th>
                        <th className={!!bookingsColumns && !bookingsColumns.shippingoperator ? "d-none" : ""}>Shipping Operator</th>
                        <th className={!!bookingsColumns && !bookingsColumns.dateslot ? "d-none" : ""}>Date / Slot</th>
                        {!!handleUpdateModal && <th className={!!bookingsColumns && !bookingsColumns.actions ? "d-none" : ""}>&nbsp;</th>}
                    </tr>
                </thead>
            }
            <tbody>
            {
                bookingsData.map((booking, i) => {
                    const booking_date = moment.unix(ticksToSeconds(booking.slotdateticks)).format("ddd Do MMM YYYY");
                    const currentSlotHighlighted = `${booking_date} ${booking.slotdescription.replace(/([0-9]{1,2})am/gi, '$1am').replace(/([0-9]{1,2})pm/gi, '$1pm')}`.startsWith(currentSlotDate);

                    return (
                        <TableCollapsibleRow key={`booking-${i}`}
                                             className={`${currentSlotHighlighted ? 'booking-current-slot' : ''} ${booking.cancelled || (booking.status === "EXP" && booking.bookingstatus !== "COM") ? "booking-faded" : ""} booking-${booking.bookingid}`}>

                            <td data-colname="Status"
                                data-colprimary className={!compactTable && !!bookingsColumns && !bookingsColumns.bookingstatus ? "d-none" : ""}>
                                <span className="booking-table-status">
                                    {compactTable && <strong className="booking-table-direction">{booking.slotdirection}</strong>}

                                    <BookingStatusBadge bookingstatus={booking.bookingstatus} noshow={booking.noshow} cancelled={booking.cancelled}/>
                                </span>
                                <span className="table-primary-preview">{booking_date} {booking.slotdescription}</span>
                            </td>
                            {
                                !compactTable &&
                                <td data-colname="Direction" className={!compactTable && !!bookingsColumns && !bookingsColumns.direction ? "d-lg-none" : ""}>
                                    {booking.slotdirection}
                                </td>
                            }
                            <td data-colname="Booking No." className={!compactTable && !!bookingsColumns && !bookingsColumns.bookingnoPin ? "d-lg-none" : ""}>
                                {
                                    bookingStatusesForPin.find(status => status === booking.bookingstatus)
                                        && booking.bookingnoPin
                                }
                            </td>
                            {
                                !compactTable &&
                                <td data-colname="Depot" className={!compactTable && !!bookingsColumns && !bookingsColumns.branchcode ? "d-lg-none" : ""}>
                                    {
                                        !!branchesError ?
                                            <span className="text-danger">Branch data error</span>
                                            :
                                            !branchesLoading && !!branchesData && getBranchName(branchesData, booking.branchcode)
                                    }
                                </td>
                            }
                            <td data-colname="Carrier" className={!compactTable && !!bookingsColumns && !bookingsColumns.carriercode ? "d-lg-none" : ""}>
                                {
                                    !!carriersError ?
                                        <span className="text-danger">Carrier data error</span>
                                        :
                                        !carriersLoading && !!carriersData && getCarrierName(carriersData, booking.carriercode)
                                }
                            </td>
                            {
                                compactTable ?
                                    <td data-colname="Container / Reference">
                                        {
                                            booking.containerno !== "" && <><strong>Con:</strong> {booking.containerno}<br/></>
                                        }
                                        {
                                            booking.bookingreference !== "" && <><strong>Ref:</strong> {booking.bookingreference}</>
                                        }
                                    </td>
                                    :
                                    <>
                                        <td data-colname="Container"
                                            className={!compactTable && !!bookingsColumns && !bookingsColumns.containerno ? "d-lg-none" : ""}>{booking.containerno}</td>
                                        <td data-colname="Booking Ref."
                                            className={!compactTable && !!bookingsColumns && !bookingsColumns.bookingreference ? "d-lg-none" : ""}>{booking.bookingreference}</td>
                                    </>
                            }
                            {
                                !compactTable &&
                                <td data-colname="Rego"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.regono ? "d-lg-none" : ""}>{booking.regono}</td>
                            }
                            {
                                !compactTable &&
                                <td data-colname="ISO"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.isocode ? "d-lg-none" : ""}>{booking.isocode}</td>
                            }
                            {
                                !compactTable &&
                                <td data-colname="Created By"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.createdbyname ? "d-lg-none" : ""}>{booking.createdbyname}</td>
                            }
                            {
                                !compactTable &&
                                <td data-colname="Commodity"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.commodity ? "d-lg-none" : ""}>{booking.commodity}</td>
                            }
                            {
                                !compactTable &&
                                <td data-colname="Shipping Operator"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.shippingoperator ? "d-lg-none" : ""}>{booking.shippingoperator}</td>
                            }
                            {
                                <td data-colname="Date / Slot"
                                    className={!compactTable && !!bookingsColumns && !bookingsColumns.dateslot ? "d-lg-none" : ""}>
                                    {booking_date}<br/>
                                    {currentSlotHighlighted ? <FontAwesomeIcon icon={faClock} /> : ''} {booking.slotdescription}
                                </td>
                            }
                            {
                                !!handleUpdateModal &&
                                <td data-colname="Actions"
                                    className={`table-actions ${!compactTable && !!bookingsColumns && !bookingsColumns.actions ? "d-lg-none" : ""}`}>
                                    {
                                        !booking.noshow && !booking.cancelled && booking.status !== "EXP" && booking.bookingstatus !== "COM" &&
                                        <Dropdown alignRight>
                                            <Dropdown.Toggle size="sm" variant={"link"}>
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleUpdateModal(booking)}>Update</Dropdown.Item>
                                                {
                                                    !booking.latecancel &&
                                                        <Dropdown.Item className="text-danger" onClick={() => handleCancelModal(booking)}>Cancel</Dropdown.Item>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }
                                </td>
                            }
                        </TableCollapsibleRow>
                    );
                })
            }
            </tbody>
        </TableCollapsible>
    );
};

BookingsTable.propTypes = {
    bookingsData: PropTypes.array.isRequired,
    carriersData: PropTypes.array.isRequired,
    carriersLoading: PropTypes.bool.isRequired,
    carriersError: PropTypes.any,
    branchesData: PropTypes.array.isRequired,
    branchesLoading: PropTypes.bool.isRequired,
    branchesError: PropTypes.any,
    compactTable: PropTypes.bool,
    handleUpdateModal: PropTypes.func,
    handleCancelModal: PropTypes.func,
    bookingsColumns: PropTypes.object,
    currentSlotDate: PropTypes.string
};

BookingsTable.defaultProps = {
    compactTable: false,
    bookingsColumns: application.bookings_columns
};

export default BookingsTable;
